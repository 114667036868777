/* eslint-disable no-unused-vars */

import { createContext } from 'react';

export const ProjectContext = createContext({
  idProj: null,
  acesso: null,
  define: (id) => {},
  reset: () => {},
});
